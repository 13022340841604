import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import color from '../../../../constants/colors';

const StyledButton = styled(props => <Link {...props} />)`
  white-space: nowrap;
  display: inline-block;
  line-height: 40px;
  padding: 0 14px;
  border-radius: 2rem;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  background: ${color.orange};
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.025em;
  color: ${color.white};
  text-decoration: none;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  &:hover {
    color: ${color.white};
    background-color: ${color.lightOrange};
    -webkit-transform: translateX(-0.4rem);
    transform: translateX(-0.4rem);
    box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
  }
`;

const Button = ({
  link, disabled, type, text,
}) => (
  <StyledButton to={link} type={type} disabled={disabled}>
    {text}
  </StyledButton>
);

export default Button;
